import { CanActivateFn, Router } from '@angular/router';

import { Auth, user, User } from '@angular/fire/auth';
import { inject } from '@angular/core';
import { map } from 'rxjs';


export const isNotLoggedGuard: CanActivateFn = (route, state) => {
  const auth = inject(Auth);
  const router = inject(Router);

  return user(auth).pipe(
    map(_user => {
      if (_user) {
        router.navigate(['/dashboard']);
        return false;
      } else {
        return true;
      }
      
    })
  )
};
